import { alpha } from "@mui/material/styles";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { FC } from "react";

const LABEL_DIMENSIONS = 36;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.up("md")]: {
        marginTop: -5,
      },
      "& .MuiDateCalendar-root": {
        justifyContent: "flex-start",
        backgroundColor: "inherit",
        width: "auto",
        maxWidth: "100%",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: {
          margin: "0 auto",
        },
        [theme.breakpoints.up("md")]: {
          margin: 0,
          maxWidth: "none",
          overflow: "visible",
        },
      },

      "& .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition": {
        minHeight: 255
      },

      "& .MuiPickersCalendarHeader-root": {
        margin: theme.spacing(1, 0, 0),
        padding: theme.spacing(0, 0, 0, 1),
      },

      "& .MuiPickersCalendarHeader-label": {
        marginLeft: theme.spacing(0.75),
      },
      "& .MuiPickersCalendarHeader-root .MuiPickersArrowSwitcher-button": {
        padding: theme.spacing(0.5),
      },
      "& .MuiPickersArrowSwitcher-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiDayCalendar-weekDayLabel": {
        height: LABEL_DIMENSIONS,
        width: LABEL_DIMENSIONS,
        color: theme.palette.text.primary,
      },
      // selectable
      "& .MuiPickersDay-root": {
        height: LABEL_DIMENSIONS + 4,
        width: LABEL_DIMENSIONS + 4,
        fontWeight: theme.typography.fontWeightMedium,
        margin: 0,

        "&:hover": {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          border: `2px solid ${theme.palette.common.white}`,
        },
      },
      // disabled
      "& .MuiPickersDay-root.Mui-disabled": {
        color: theme.palette.grey[500],
        fontWeight: "normal",
      },
      // today
      "& .MuiPickersDay-root.MuiPickersDay-today": {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold,
      },
      // selected
      "& .MuiPickersDay-root.Mui-selected, .MuiPickersDay-root.Mui-selected.MuiPickersDay-today": {
        backgroundColor: theme.colors.logo.darkness,
        border: 0,
        color: theme.palette.common.white,
        "&:hover": {
          backgroundColor: theme.colors.logo.darkness,
        },
        // Initial load dont show current day as selected
        "&.Mui-disabled": {
          backgroundColor: "transparent",
          color: theme.palette.grey[500],
        },
      },

      // DateRangeCalendar
      "& .MuiDateRangePickerDay-root": {
        "& .MuiPickersDay-root": {
          height: LABEL_DIMENSIONS,
          width: LABEL_DIMENSIONS,
        },
        // Dashed preview selection
        "& .MuiDateRangePickerDay-rangeIntervalDayPreview": {
          borderTopColor: theme.palette.grey[400],
          borderBottomColor: theme.palette.grey[400],
        },
        "&:last-of-type .MuiDateRangePickerDay-rangeIntervalDayPreview": {
          borderRightColor: theme.palette.grey[400],
        },
        "&:first-of-type .MuiDateRangePickerDay-rangeIntervalDayPreview": {
          borderLeftColor: theme.palette.grey[400],
        },
      },
      "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
        backgroundColor: theme.palette.grey[200],
        "&.MuiDateRangePickerDay-hiddenDayFiller": {
          backgroundColor: "transparent",
        },
      },
    },
  }),
  {
    classNamePrefix: "DateCalendarStylesWrapper",
  }
);

export type DateCalendarStylesWrapperJSSClassKey = keyof ReturnType<typeof useStyles>;

export type DateCalendarStylesWrapperProps = {
  classes?: Partial<ClassNameMap<DateCalendarStylesWrapperJSSClassKey>>;
};

export const DateCalendarStylesWrapper: FC<DateCalendarStylesWrapperProps> = ({ classes: extClasses, children }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  /********************/
  /*    useCallback   */
  /********************/

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return <div className={classes.root}>{children}</div>;
};
